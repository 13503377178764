* {
    box-sizing: border-box;
}
body,
html {
    width: 100%;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    background: #ecf0f5;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
a {
    text-decoration: none;
}
#root {
    height: 100%;
}
/*OVERRIDE ANT BLEAH */
label {
    font-size: 1rem;
}
.Root-Icon {
    border: 0;
}
.ant-avatar-string {
    position: relative !important;
    left: 0 !important;
}
.ant-btn,
.ant-input,
.ant-card,
.ant-popover {
    font-size: 1rem;
}
.ant-layout-sider-children,
.ant-layout-header {
    z-index: 9;
}
.ant-layout-sider.ant-layout-sider-collapsed {
    z-index: 999;
}

.ant-layout h1 {
    font-size: 2em;
}

.ant-input-affix-wrapper .ant-input-suffix {
    right: 1rem;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    padding: 0.5rem;
}

tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: -0.5rem -0.5rem -0.5rem;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #595959;
}

.ant-table {
    font-size: 1rem;
}
.ant-alert-with-description .ant-alert-message {
    font-size: 1rem;
}
.ant-alert-description {
    font-size: 1rem;
}

.ant-menu-item,
.ant-menu-item a,
.ant-menu-submenu-title {
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
}

.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
    background-color: #5a6872;
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
}

.ant-menu-sub {
    background: #152935 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-image: linear-gradient(315deg, #00c3ea, #0090d1);
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-submenu-disabled > a {
    color: #fff !important;
}

/* END OVERRIDE ANT BLEAH */

.no-overflow {
    overflow: hidden;
}
#_elev_io ._xy6qs {
    top: inherit !important;
    bottom: 100px;
}

.uv-icon.uv-bottom-right {
    right: 0 !important;
    bottom: 110px !important;
}

.row-responsive {
    margin: 1.5rem;
    text-align: center;
}
.col-responsive {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 1rem;
    padding-left: 1rem;
    display: inline-block;
}

.labelForm {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-right: 0px;
    border-radius: 0.25rem;
    position: relative;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: 2rem;
    padding-top: 0.5rem;
    margin-top: 0.25rem;
    width: 9.25rem;
}

.selectInput .ant-select-selection--single {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 2rem;
    padding-top: 0.25rem;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
}
.dateInput .ant-calendar-picker-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 2rem;
    padding-top: 0.25rem;
    margin-top: 0.25rem;
}
.radioInput .ant-radio-button-wrapper:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 2rem;
    padding-top: 0.25rem;
    margin-top: 0.25rem;
}
.treeInput .ant-select-selection--multiple {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    min-height: 2rem;
    padding-top: 0.25rem;
    margin-top: 0.25rem;
}
.uv-icon-trigger {
    visibility: hidden;
}
