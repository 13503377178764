.Login {
    margin-top: 20vh;
}

.Login-form-forgot {
    float: right;
}

.Login-form-button {
    width: 100%;
}

.Login-header {
    text-align: center;
    margin: 2rem;
}

.Login-header-img {
    height: 5rem;
}

.Login-header-text {
    font-size: 2rem;
    font-weight: 200;
}

.c-reset-password .header-img {
    max-width: 80%;
}

.c-reset-password .captcha-err {
    font-size: 1rem;
    color: #f5222d;
}
